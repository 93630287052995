import { CheckIcon } from '@heroicons/react/20/solid'

const includedFeatures = [
  'Only used in "luis.network" domain, never in third-party websites',
  'No personal information, tracking or analytics'
]

export default function App() {
  return (
    <div className="py-24 sm:py-32 bg-gray-900 h-full pb-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-100 sm:text-4xl">
            Install network certificate
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-400">
            Some services require you to use encrypted connections. To do so, you need to install the certificate
            authority in your device.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-600 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-100">*.luis.network</h3>
            <p className="mt-6 text-base leading-7 text-gray-400">
              All secure services are hosted in the <code className='text-indigo-400'>luis.network</code> domain. Validate the
              url before interacting with any service.
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-400">Privacy Policy</h4>
              <div className="h-px flex-auto bg-gray-800" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-400 sm:grid-cols-1 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <CheckIcon className="h-6 w-5 flex-none text-indigo-400" aria-hidden="true" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-sm px-8">
                <p className="text-base font-semibold text-gray-600">Certificate Authority</p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-2xl font-bold tracking-tight text-gray-900 -mr-1">authority</span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600 -ml-1">.crt</span>
                </p>
                <a
                  href="/ssl/authority.crt"
                  download="authority.crt"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Download
                </a>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  If you need help installing the certificate authority, please refer to the{' '}
                  <a href="#" className="font-semibold text-gray-900 hover:text-gray-700">
                    documentation
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
